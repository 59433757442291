/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer, Button, Select } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import useAuth from "../../../hooks/useAuth";
import cssStyles from "../../../utils/cssStyles";
import { NAVBAR } from "../../../config";
import Logo from "../../../components/Logo";
import Scrollbar from "../../../components/other/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import navConfig from "./NavConfig";
import NavbarAccount from "./NavbarAccount";
import CollapseButton from "./CollapseButton";
import packageJson from "../../../../package.json";
import { useGetRoomListQuery } from "../../../generated/graphql";
import { MenuItem } from "@mui/material";
import * as Sentry from "@sentry/react";
import { getActiveRoomIdAndName } from "../../../utils/getActiveRoomIdAndName";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const { logout, activeRoomName, setActiveRoom, jwtDecoded } = useAuth();
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const [{ error: errorRoomListData, data: roomListData }] =
    useGetRoomListQuery({});

  useEffect(() => {
    if (errorRoomListData) {
      toast.error("Error fetching room list data");
    }
  }, [errorRoomListData]);

  const roomList = roomListData?.getRoomList;

  useEffect(() => {
    const { activeRoomId, activeRoomName } = getActiveRoomIdAndName();
    // If active room is not set, set the first room from the list
    if (activeRoomId && activeRoomName) {
      setActiveRoom(activeRoomName, activeRoomId);
    } else if (roomList) {
      setActiveRoom(roomList[0]?.name, roomList[0]?.id);
    }
  }, [roomList]);

  const handleChangeActiveRoom = (event: any) => {
    const activeRoomName = event.target.value;
    const activeRoom = roomList?.find(
      (room: any) => room?.name === activeRoomName
    );
    if (!activeRoom) {
      console.error("Active room not found");
      return;
    }
    setActiveRoom(activeRoom?.name, activeRoom?.id);
  };

  useEffect(() => {
    if (jwtDecoded) {
      Sentry.setUser({
        id: jwtDecoded.adminId,
        name: jwtDecoded?.name,
        role: jwtDecoded.adminRoleName,
      });
    }
  }, [jwtDecoded]);

  const renderContent = (
    <Scrollbar
      sx={{
        backgroundColor: isLight ? "#fbfbfb" : "#20272f",
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "inline",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo title={!isCollapse} />

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        {!isCollapse && (
          <NavbarAccount isCollapse={isCollapse} operator={jwtDecoded} />
        )}

        {!isCollapse && (
          <Box
            sx={{
              m: "auto",
            }}
          >
            {/* Select from rooms list and default value is the first one */}
            <Select
              size="small"
              sx={{
                backgroundColor: "#2e3c5a",
                color: "#fff",
              }}
              fullWidth
              disabled={roomList?.length! < 2}
              value={activeRoomName}
              onChange={(event) => {
                handleChangeActiveRoom(event);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#2e3c5a",
                    color: "#fff",
                    maxHeight: 300,
                    scrollbarColor: "#fff #2e3c5a",
                  },
                },
              }}
              inputProps={{
                sx: {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {roomList?.map((room: any) => (
                <MenuItem key={room?.name} value={room?.name}>
                  {room?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 2,
          }}
        >
          <Button
            onClick={async () => {
              await logout();
            }}
            size="large"
            variant="outlined"
            fullWidth
            color="inherit"
          >
            Logout
          </Button>
        </Box>
      )}

      {!isCollapse && (
        <Box
          sx={{
            color: "#949494",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 1.5,
          }}
        >
          Version {packageJson.version}
          {/* {import.meta.env.REACT_APP_STAGING ? " - Staging" : null} */}
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
