import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Iconify from "../components/Iconify";

// @mui
import {
  Container,
  Box,
  CircularProgress,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import Page from "../components/Page";
import useSettings from "../hooks/useSettings";
import { useGetPlayerByIdQuery } from "../generated/graphql";
import { useNavigate } from "react-router-dom";
import PlayerAccount from "../components/PlayerAccount";
import PlayerTransactions from "../components/PlayerTransactions";
import PlayTransactions from "../components/PlayTransactions";
import PlayerPets from "../components/PlayerPets";
// ----------------------------------------------------------------------

export default function PagePlayer() {
  const params = new URLSearchParams(window.location.search);
  const playerId = params.get("id");
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [{ data: getPlayerResult, error: getPlayerError }, getUpdatedPlayer] =
    useGetPlayerByIdQuery({
      variables: {
        playerId: playerId!.toString(),
      },
      requestPolicy: "network-only",
      pause: !playerId,
    });
  const player = getPlayerResult?.getPlayerById;

  useEffect(() => {
    if (getPlayerError) toast.error(`ERR: ${getPlayerError}`);
  }, [getPlayerError]);

  // Tabs Configs ---------------------------------------------------------
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              mt: 2,
              p: {
                xs: 0,
                sm: 3,
              },
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // ----------------------------------------------------------------------

  if (!playerId)
    return (
      <>
        <Box
          sx={{
            display: "flex",
            m: "auto",
            height: "100%",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Iconify
            icon="ep:back"
            sx={{
              cursor: "pointer",
              ":hover": { color: "red" },
              mr: 2,
              my: "auto",
              width: "30px",
              height: "30px",
            }}
            onClick={() => navigate("/players")}
          />
          <Typography variant="h4" component="h4">
            Player not found
          </Typography>
        </Box>
      </>
    );

  // ----------------------------------------------------------------------

  if (!player)
    return (
      <Box
        sx={{
          display: "flex",
          m: "auto",
          height: "100%",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  // ----------------------------------------------------------------------

  return (
    <Page title={`${playerId}`}>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="ep:back"
            width={30}
            height={30}
            sx={{ cursor: "pointer", ":hover": { color: "red" } }}
            onClick={() => window.history.back()}
          />

          <Iconify
            icon="fa-solid:user-circle"
            sx={{ ml: 2 }}
            width={40}
            height={40}
          />

          <Typography
            variant="h3"
            component="h3"
            paragraph
            gutterBottom
            sx={{
              ml: 1,
              mt: "auto",
              mb: "auto",
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            {player?.firstName} {player?.lastName || ""}
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Account Info" {...a11yProps(0)} />
              <Tab label="Transactions" {...a11yProps(1)} />
              <Tab label="Play Transactions" {...a11yProps(2)} />
              <Tab label="Pets" {...a11yProps(3)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <PlayerAccount
              player={player}
              getUpdatedPlayer={getUpdatedPlayer}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <PlayerTransactions player={player} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <PlayTransactions player={player} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <PlayerPets player={player} />
          </CustomTabPanel>
        </Box>
      </Container>
    </Page>
  );
}
