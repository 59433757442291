import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const TwoFactorAuth = ({
  onSubmit,
  loading,
  error,
}: {
  onSubmit: (code: string) => void;
  loading: boolean;
  error?: string;
}) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;
      return newCode;
    });

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleSubmit = () => {
    onSubmit(code.join(''));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" align="center" gutterBottom>
        Enter 2FA Code
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {code.map((digit, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            margin="dense"
            sx={{ width: '50px', textAlign: 'center' }}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, index)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
          />
        ))}
      </Box>
      <LoadingButton
        id={'2fa-submit-button'}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
        type="submit"
        loading={loading}
        onClick={handleSubmit}
        sx={{
          mt: 2,
          textTransform: 'initial',
          fontWeight: '400',
        }}
      >
        Verify
      </LoadingButton>
    </Box>
  );
};
