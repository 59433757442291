import axios, { type AxiosInstance } from "axios";
export class Auth {
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_APP_API!,
      headers: {
        Accept: "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        console.debug(`LOGIN request: ${config.method} ${config.url}`);
        return config;
      },
      (error) => {
        console.error(`LOGIN request: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        console.debug(
          `LOGIN response: ${response.status} ${response.statusText}`
        );
        return response;
      },
      (error) => {
        console.error(`LOGIN response: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );
  }

  async login(
    email: string,
    password: string,
    twoFAToken?: string
  ): Promise<{ token: string; refreshToken: string }> {
    const response = await this.axiosInstance.post<{
      token: string;
      refreshToken: string;
    }>("/login", { email, password, twoFAToken });
    return response.data;
  }

  async logout(): Promise<number> {
    const response = await this.axiosInstance.post(
      "/logout",
      {},
      { headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` } }
    );
    return response.status;
  }

  isJwtStale(jwt: string): boolean {
    const payload = JSON.parse(atob(jwt.split(".")[1]));
    const expiration = payload.exp * 1000;
    return Date.now() >= expiration;
  }

  async refresh(): Promise<{ token: string; refreshToken: string }> {
    const response = await this.axiosInstance.get("/refreshtoken", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
    });
    return response.data;
  }
}
